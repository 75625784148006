body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  background: #f0f0f0;
  overflow: hidden;
}

.App {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

section {
  scroll-snap-align: start;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  text-align: center;
}

.transition-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1000;
  pointer-events: none;
}

.transition-rect {
  width: 0%;
  height: 100%;
  background: #333333;
}

.hero {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f0;
  color: #333333;
}

.hero-content {
  position: relative;
  text-align: center;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.word {
  font-size: 4rem;
  margin: 0;
  opacity: 0;
  position: absolute;
}

.word-1 {
  top: 20%;
  left: 10%;
}

.word-2 {
  bottom: 20%;
  right: 10%;
}

.word-3 {
  top: 50%;
  right: 30%;
}

.word-4 {
  bottom: 50%;
  left: 30%;
}

section h2 {
  margin-bottom: 1rem;
}

section p {
  max-width: 600px;
  margin-bottom: 1rem;
  line-height: 1.6;
}
